require('dotenv').config()

module.exports = {
  siteMetadata: {
    title: 'EF Design',
    description: 'Home of everything creative, digital and brand at EF.',
    author: 'EF Global Creative',
    url: 'https://ef.design',
  },
  plugins: [
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
        homeSlug: 'home',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        resolveRelations: ['CTA-block.items'],
      },
    },

    {
      resolve: `gatsby-plugin-netlify`,
      options: {
        mergeSecurityHeaders: false,
        headers: {
          '/editor': [
            // matching headers (by type) are replaced by Netlify with more specific routes
            "x-frame-options: ''",
          ],
        }, // option to add more headers. `Link` headers are transformed by the below criteria
      },
    },
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'EF Design',
        short_name: 'EF Design',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#191919',
        display: 'minimal-ui',
        icon: 'src/images/ef-logo.png', // This path is relative to the root of the site.
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: 'UA-87282423-3',
      },
    },
    'gatsby-plugin-sass',
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.app/offline
    //'gatsby-plugin-offline',
  ],
}
